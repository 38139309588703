<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i>FTP Logs</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row listing">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-inline">
                                <div class="form-group mr-1">
                                    <input type="date" v-model="filter.from" class="form-control" placeholder="From Date"/>
                                </div>
                                <div class="form-group mr-1">
                                    <input type="date" v-model="filter.to" class="form-control" placeholder="To Date"/>
                                </div>
                                <ejs-progressbutton ref="progressBtn" content="SEARCH" :duration="10000" style="color: white; background-color: #383838;" :spinSettings="spinSettings" v-on:click.native="search"></ejs-progressbutton>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div class="control-section">
                        <div class="content-wrapper">
                            <ejs-grid ref="overviewgrid" :dataSource="items" :childGrid="childGrid" :allowSorting="true" :toolbar='toolbar'
                                      :toolbarClick='toolbarClick' :allowExcelExport='true'>
                                <e-columns>
                                    <e-column field='date' headerText='Date'></e-column>
                                    <e-column field='status' headerText='Status' :template="statusTemplate"></e-column>
                                    <e-column field='reason' headerText='Errors' width='140' :template="errorTemplate"></e-column>
                                </e-columns>
                            </ejs-grid>
                        </div>
                    </div>
                </div>
            </div>
            <ejs-toast ref='elementRef' id='element' title='' content='' :position='position'></ejs-toast>
        </div>



    </div>
</template>

<script>
    import axios from 'axios'

    import { GridPlugin, DetailRow, Page, ExcelExport, Toolbar } from '@syncfusion/ej2-vue-grids';
    import { ToastPlugin } from "@syncfusion/ej2-vue-notifications";
    import Vue from "vue";

    Vue.use(GridPlugin);
    Vue.use(ToastPlugin);

    export default {
        name: 'ErrorLogs',
        props:['user'],
        components: {},
        provide: {
            grid: [DetailRow, Page, ExcelExport, Toolbar]
        },
        computed: {
          childGrid() {
              let child = [];
              for(var i in this.items) {
                  if (this.items[i].reason && this.items[i].reason.length) {
                      if (typeof this.items[i].reason !== 'string') {
                          for (var v in this.items[i].reason) {
                              child.push({_id: this.items[i]._id, reason: this.items[i].reason[v]})
                          }
                      } else {
                          child.push({_id: this.items[i]._id, reason: this.items[i].reason})
                      }
                  }
              }
              return {
                  dataSource: child,
                  queryString: "_id",
                  allowPaging: true,
                  pageSettings: { pageSize: 6, pageCount: 5 },
                  columns: [
                      { field: "reason", headerText: "Reason" }
                  ]
              }
          }
        },
        data: function () {
            return {
                campaigns:[],
                items: [],
                filter: {from:'', to: '', status:''},
                searching: false,
                toolbar: ['ExcelExport'],
                groupOptions: { columns: ["date"] },
                spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
                position: { X: 'Right', Y: 'Bottom' },
                statusTemplate: function () {
                    return {
                        template: Vue.component('statusTemplate', {
                            template: `<div id="status" :class="{'statustemp': true, 'e-activecolor': data.status == active, 'e-inactivecolor': data.status == inactive}">\
                                        <span :class="{'statustxt': true, 'e-activecolor': data.status == active, 'e-inactivecolor': data.Status == inactive}">{{data.status}}</span></div>`,
                            data: function () { return { data: {}, active: 'success', inactive: 'failed' }; }
                        })
                    }
                },
                errorTemplate: function () {
                    return {
                        template: Vue.component('errorTemplate', {
                            template: `<div>{{data.reason ? typeof data.reason !== 'string' ? data.reason.length : 1 : ''}}</div>`,
                            data: function () { return { data: {} }; }
                        })
                    }
                }
            }
        },
        created: function(){
            window.addEventListener("keydown",this.windowListener);
        },
        destroyed: function() {
            window.removeEventListener("keydown",this.windowListener);
        },
        mounted: function(){
            this.filter.from = this.getDateFormatted(new Date());
            this.filter.to = this.getDateFormatted(new Date());
            this.search();
        },
        methods:{
            toolbarClick: function () {
                this.$refs.overviewgrid.excelExport({hierarchyExportMode: 'All'});
            },
            view: function(data) {
                data.show = data.show ? false : true;
                this.$forceUpdate()
            },
            windowListener: function(e){
                if(e.keyCode === 13 && !this.searching){
                    this.search();
                }
            },
            search: function(){
                this.searching = true;

                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};

                if(this.filter.from) request.filters.from = this.filter.from;
                if(this.filter.to) request.filters.to = this.filter.to;
                if(this.filter.type) request.filters.type = this.filter.type;

                axios.get(`${this.$root.serverUrl}/admin/reports/ftp/logs`,{params:request}).then((resp) => {
                    if (resp.data.data && resp.data.data.length) {
                        this.items = resp.data.data;
                    } else {
                        this.items = [];
                    }
                    this.$refs.progressBtn.progressComplete()
                    this.$root.preloader = false;
                }).catch(() => {
                    this.$root.preloader = false;
                    this.$refs.progressBtn.progressComplete()
                    this.$refs.elementRef.show( { title: 'Error !', content: 'A problem has been occurred while fetching your data.', cssClass: 'e-toast-danger' });
                });
            },
            getDateFormatted: function(d){
                var
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                return [year, month, day].join('-');
            },
        }
    }
</script>

<style>

    #overviewgrid td.e-rowcell .statustxt.e-activecolor {
        color: #00cc00;
        position: relative;
        top: 9px;
    }

    #overviewgrid td.e-rowcell .statustxt.e-inactivecolor {
        color: #e60000;
        position: relative;
        top: 9px;
    }

    .statustemp.e-inactivecolor {
        background-color: #ffd7cc;
        width: 64px;
    }

    .statustemp.e-activecolor {
        background-color: #ccffcc;
        width: 57px;
    }

    .statustxt.e-activecolor {
        color: #00cc00;
    }

    .statustxt.e-inactivecolor {
        color: #e60000;
    }

    .statustemp {
        position: relative;
        height: 19px;
        border-radius: 5px;
        text-align: center
    }
    #grid_1902874750_0_excelexport {
        background-color: #28a745 !important;
        color: white !important;
    }
    .e-btn-icon.e-excelexport.e-icons.e-icon-left {
        color: white !important;
    }
</style>
